"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatToTimestamp = exports.getYear = exports.getMonth = exports.getWeekDetails = exports.getTodayDetails = exports.getThemeColor = exports.getLastUserName = exports.getInitials = void 0;
const getInitials = (name) => {
    if (!name) {
        name = '';
    }
    const matches = name.match(/\b(\w)/g);
    let letters = '';
    if (matches && matches.length > 0) {
        letters = matches.slice(0, 2).join('').toUpperCase();
    }
    return letters;
};
exports.getInitials = getInitials;
const getLastUserName = (userId, basicUsers) => {
    let returnValue = '';
    if (!userId) {
        const primaryUser = basicUsers.find((user) => user.primary === true);
        if (primaryUser) {
            returnValue = primaryUser.name;
        }
    }
    if (userId === 'system') {
        return 'Automated';
    }
    const specificUser = basicUsers.find((user) => user._id === userId);
    if (specificUser) {
        returnValue = specificUser.name;
    }
    return returnValue;
};
exports.getLastUserName = getLastUserName;
const getThemeColor = (userId, basicUsers) => {
    if (userId === 'system') {
        return '#7f7f7f';
    }
    let returnValue = '';
    if (!userId) {
        const primaryUser = basicUsers.find((user) => user.primary === true);
        if (primaryUser) {
            returnValue = primaryUser.themeColor;
        }
    }
    const specificUser = basicUsers.find((user) => user._id === userId);
    if (specificUser) {
        returnValue = specificUser.themeColor;
    }
    return returnValue;
};
exports.getThemeColor = getThemeColor;
const getTodayDetails = () => {
    const date = new Date();
    const fromDate = new Date(date.setHours(0, 0, 0, 0));
    const toDate = new Date(date.setHours(23, 59, 59, 999));
    return { fromDate, toDate };
};
exports.getTodayDetails = getTodayDetails;
const getWeekDetails = (date = new Date()) => {
    const dayOfWeek = date.getDay();
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
    const currentDay = new Date(date);
    currentDay.setHours(23, 59, 59, 999);
    return {
        startOfWeek,
        endOfWeek,
        currentDay,
    };
};
exports.getWeekDetails = getWeekDetails;
const getMonth = () => {
    const currentDay = new Date();
    const startOfMonth = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);
    const endOfMonth = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);
    currentDay.setHours(23, 59, 59, 999);
    return { startOfMonth, endOfMonth, currentDay };
};
exports.getMonth = getMonth;
const getYear = () => {
    const currentDay = new Date();
    const startOfYear = new Date(currentDay.getFullYear(), 0, 1);
    startOfYear.setHours(0, 0, 0, 0);
    const endOfYear = new Date(currentDay.getFullYear(), 11, 31);
    endOfYear.setHours(23, 59, 59, 999);
    currentDay.setHours(23, 59, 59, 999);
    return { startOfYear, endOfYear, currentDay };
};
exports.getYear = getYear;
const formatToTimestamp = (date, isToDate = false) => {
    if (!date) {
        return;
    }
    if (isToDate) {
        date.setHours(23, 59, 59, 999);
    }
    else {
        date.setHours(0, 0, 0, 0);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
exports.formatToTimestamp = formatToTimestamp;
