"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colors = exports.DrawerCloseWidth = exports.DrawerWidth = void 0;
const styles_1 = require("@mui/material/styles");
exports.DrawerWidth = 250;
exports.DrawerCloseWidth = 65;
// Colors for Treat Theme
const TreatColors = {
    error: '#ef5350',
    dark01: '#2d3449',
    primary: '#00ABB3',
    secondary: '#8316E2',
    contrastText: '#FFFFFF',
    text: '#FFFFFF',
    primaryBackground: '#FFFFFF',
    light01: '#F3F4F7',
    light02: '#E7E9EF',
    light03: '#DADDE7',
    green: '#34C759',
    green01: '#53D2A2',
    surface01: '#FAFBFC',
    navBarColor: '#4c5773',
    tooltipBackground: '#fafbfc',
    darkRed: 'rgba(255, 0, 0, 0.5)',
    barColor: '#1FBFAF',
    remainingBarColor: '#CEDFDD',
    strokeColor: '#fff',
    dark04: '#626e93',
};
// Colors for Apex Theme
const ApexColors = {
    error: '#ef5350',
    dark01: '#121212',
    primary: '#8BD600',
    secondary: '#8316E2',
    contrastText: '#FFFFFF',
    text: '#FFFFFF',
    primaryBackground: '#FFFFFF',
    light01: '#F3F4F7',
    light02: '#E7E9EF',
    light03: '#DADDE7',
    green: '#34C759',
    green01: '#53D2A2',
    surface01: '#FAFBFC',
    navBarColor: '#121212',
    tooltipBackground: '#fafbfc',
    darkRed: 'rgba(255, 0, 0, 0.5)',
    barColor: '#8BD600',
    remainingBarColor: '#CEDFDD',
    strokeColor: '#fff',
    dark04: '#626e93',
};
const hexToRgb = (hex) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `${r}, ${g}, ${b}`;
};
const applyThemeVariables = (colors) => {
    const root = document.documentElement;
    root.style.setProperty('--primary-color', colors.primary);
    root.style.setProperty('--secondary-color', colors.secondary);
    root.style.setProperty('--primary-color-rgb', hexToRgb(colors.primary));
    root.style.setProperty('--background-color', colors.primaryBackground);
    root.style.setProperty('--text-color', colors.text);
    root.style.setProperty('--nav-bar-color', colors.navBarColor);
    root.style.setProperty('--dark-01-color', colors.dark01);
};
const storedTheme = localStorage.getItem('theme') || 'treat';
exports.Colors = storedTheme === 'apex' ? ApexColors : TreatColors;
applyThemeVariables(exports.Colors);
const defaultTheme = (0, styles_1.createTheme)();
const theme = (0, styles_1.createTheme)({
    palette: {
        primary: {
            main: exports.Colors.primary,
            contrastText: exports.Colors.contrastText,
        },
        secondary: {
            main: exports.Colors.secondary,
            contrastText: exports.Colors.contrastText,
        },
        info: {
            main: exports.Colors.primary,
        },
        success: {
            main: exports.Colors.contrastText,
        },
        error: {
            main: exports.Colors.error,
        },
        custom: {
            white: exports.Colors.text,
            primaryBackground: exports.Colors.primaryBackground,
            light01: exports.Colors.light01,
            light02: exports.Colors.light02,
            green01: exports.Colors.green01,
            dark01: exports.Colors.dark01,
            light03: exports.Colors.light03,
            surface01: exports.Colors.surface01,
            green: exports.Colors.green,
        },
    },
    breakpoints: {
        values: Object.assign(Object.assign({}, defaultTheme.breakpoints.values), { reminderPageBreakPoint: 800, isTab: 1025, isMobile: 601 }),
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: exports.DrawerWidth,
                    borderRadius: '0px 0px 0px 0px',
                    borderRight: `1px solid none`,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: exports.Colors.secondary,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: exports.Colors.text,
                        backgroundColor: exports.Colors.primary,
                    },
                    '&.Mui-selected:hover': {
                        color: exports.Colors.text,
                        backgroundColor: exports.Colors.primary,
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: 'Nunito, sans-serif',
    },
});
exports.default = theme;
