"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetSuccessStep = exports.invoicesSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const constants_1 = require("@appConstants/constants");
const invoices_1 = require("@services/invoices");
const initialState = {
    getInvoiceStatus: constants_1.REQUEST_STATUS.PENDING,
    createInvoiceStatus: constants_1.REQUEST_STATUS.IDLE,
    invoice: undefined,
    invoiceSuccessStep: false,
};
exports.invoicesSlice = (0, toolkit_1.createSlice)({
    name: 'invoices',
    initialState,
    reducers: {
        resetSuccessStep(state) {
            state.invoiceSuccessStep = false;
        },
    },
    extraReducers: (builder) => {
        // builder
        //   .addCase(fetchInvoice.pending, (state) => {
        //     state.getInvoiceStatus = REQUEST_STATUS.PENDING;
        //   })
        //   .addCase(fetchInvoice.fulfilled, (state, action) => {
        //     if (action.payload && Array.isArray(action.payload)) {
        //       state.invoice = action.payload;
        //       state.getInvoiceStatus = REQUEST_STATUS.SUCCEEDED;
        //     } else {
        //       state.getInvoiceStatus = REQUEST_STATUS.FAILED;
        //       toast('Error loading the invoice', {
        //         type: 'error',
        //       });
        //     }
        //   })
        //   .addCase(fetchInvoice.rejected, (state) => {
        //     state.getInvoiceStatus = REQUEST_STATUS.FAILED;
        //     toast('Error loading the invoice', {
        //       type: 'error',
        //     });
        //   });
        builder
            .addCase(invoices_1.generateInvoice.pending, (state) => {
            state.createInvoiceStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(invoices_1.generateInvoice.fulfilled, (state, action) => {
            if (action.payload) {
                state.invoice = action.payload;
                state.createInvoiceStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                state.invoiceSuccessStep = true;
                (0, react_toastify_1.toast)('Invoice Sent Successfully!', {
                    type: 'success',
                });
            }
            else {
                state.createInvoiceStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error generating the invoice', {
                    type: 'error',
                });
            }
        })
            .addCase(invoices_1.generateInvoice.rejected, (state) => {
            state.createInvoiceStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error generating the invoice', {
                type: 'error',
            });
        });
    },
});
exports.resetSuccessStep = exports.invoicesSlice.actions.resetSuccessStep;
exports.default = exports.invoicesSlice.reducer;
