"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTheme = exports.setActivationKey = exports.setSelectedTenant = exports.tenantsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_toastify_1 = require("react-toastify");
const tenants_1 = require("@services/tenants");
const constants_1 = require("@appConstants/constants");
const defaultPermissions_1 = require("@appConstants/defaultPermissions");
const initialTheme = localStorage.getItem('theme') || 'treat';
const initialState = {
    tenants: undefined,
    currentTenantSettings: undefined,
    selectedTenant: undefined,
    getTenantsStatus: constants_1.REQUEST_STATUS.IDLE,
    createTenantStatus: constants_1.REQUEST_STATUS.IDLE,
    getTenantSettingStatus: constants_1.REQUEST_STATUS.IDLE,
    activationKey: '',
    activationKeyStatus: constants_1.REQUEST_STATUS.IDLE,
    permissions: {},
    archiveTenants: undefined,
    getArchiveTenantsStatus: constants_1.REQUEST_STATUS.IDLE,
    analyticsStatus: constants_1.REQUEST_STATUS.IDLE,
    appointmentsToday: 0,
    scheduledProduction: 0,
    unscheduledTx: 0,
    unscheduledTxPatients: 0,
    pastDueAR: 0,
    newPatientsScheduled: 0,
    unscheduledFamily: 0,
    unscheduledHygiene: 0,
    appointmentAnalytics: [],
    completedProduction: 0,
    scheduleProduction: 0,
    storedTheme: initialTheme,
};
exports.tenantsSlice = (0, toolkit_1.createSlice)({
    name: 'tenants',
    initialState,
    reducers: {
        setSelectedTenant: (state, action) => {
            state.selectedTenant = action.payload;
        },
        setActivationKey: (state) => {
            state.activationKey = '';
        },
        setTheme: (state, action) => {
            state.storedTheme = action.payload;
            localStorage.setItem('theme', action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(tenants_1.fetchTenants.pending, (state) => {
            state.tenants = [];
            state.selectedTenant = undefined;
            state.getTenantsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchTenants.fulfilled, (state, action) => {
            if (action.payload) {
                state.tenants = action.payload;
                state.getTenantsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the tenants', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.fetchTenants.rejected, (state) => {
            state.tenants = [];
            state.getTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the tenants', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.fetchArchiveTenants.pending, (state) => {
            state.archiveTenants = [];
            state.selectedTenant = undefined;
            state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchArchiveTenants.fulfilled, (state, action) => {
            if (action.payload) {
                state.archiveTenants = action.payload;
                state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
            }
        })
            .addCase(tenants_1.fetchArchiveTenants.rejected, (state) => {
            state.archiveTenants = [];
            state.getArchiveTenantsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the archive tenants', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.archiveTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.archiveTenant.fulfilled, (state, action) => {
            if (action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)(' Tenant archived successfully! ', {
                    type: 'success',
                    autoClose: 4000,
                });
            }
            else {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            }
        })
            .addCase(tenants_1.archiveTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Failed to archive the tenant ', {
                type: 'error',
                autoClose: 4000,
            });
        });
        builder
            .addCase(tenants_1.restoreTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.restoreTenant.fulfilled, (state, action) => {
            if (action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)(' Tenant restored successfully! ', {
                    type: 'success',
                    autoClose: 4000,
                });
            }
            else {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            }
        })
            .addCase(tenants_1.restoreTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Failed to restore the tenant ', {
                type: 'error',
                autoClose: 4000,
            });
        });
        builder
            .addCase(tenants_1.createTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.createTenant.fulfilled, (state, action) => {
            if (!action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error creating a new tenant', {
                    type: 'error',
                });
                return;
            }
            state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The tenant has been created successfully', {
                type: 'success',
            });
        })
            .addCase(tenants_1.createTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error creating a new tenant', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.updateTenant.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.updateTenant.fulfilled, (state, action) => {
            if (!action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating the tenant', {
                    type: 'error',
                });
                return;
            }
            state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The tenant has been updated successfully', {
                type: 'success',
            });
        })
            .addCase(tenants_1.updateTenant.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the tenant', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.fetchActivationKey.pending, (state) => {
            state.activationKey = '';
            state.activationKeyStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchActivationKey.fulfilled, (state, action) => {
            if (!action.payload) {
                state.activationKey = '';
                state.activationKeyStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error getting an activation key', {
                    type: 'error',
                });
                return;
            }
            state.activationKey = action.payload;
            state.activationKeyStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(tenants_1.fetchActivationKey.rejected, (state) => {
            state.activationKey = '';
            state.activationKeyStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error getting an activation key', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.fetchTenantSettings.pending, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchTenantSettings.fulfilled, (state, action) => {
            var _a, _b;
            if (action.payload) {
                state.currentTenantSettings = action.payload.tenant;
                state.permissions = (_a = action.payload.permissions) !== null && _a !== void 0 ? _a : defaultPermissions_1.defaultPermissionsForPayload;
                state.permissions['Home'] = state.permissions['Appointments'];
                delete state.permissions['Appointments'];
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                state.storedTheme = (_b = action.payload.tenant.theme) !== null && _b !== void 0 ? _b : 'treat';
                localStorage.setItem('theme', action.payload.tenant.theme);
            }
            else {
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the tenant settings', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.fetchTenantSettings.rejected, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the tenant settings', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.updateTenantSettings.pending, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.updateTenantSettings.fulfilled, (state, action) => {
            if (action.payload) {
                state.currentTenantSettings = action.payload;
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
                (0, react_toastify_1.toast)('Office setting has been updated successfully', {
                    type: 'success',
                });
            }
            else {
                state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating the office settings', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.updateTenantSettings.rejected, (state) => {
            state.currentTenantSettings = undefined;
            state.getTenantSettingStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the office settings', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.updateTenantProfile.pending, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.updateTenantProfile.fulfilled, (state, action) => {
            if (!action.payload) {
                state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error updating the office profile', {
                    type: 'error',
                });
                return;
            }
            state.createTenantStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            (0, react_toastify_1.toast)('The office profile has been updated successfully', {
                type: 'success',
            });
            state.currentTenantSettings = action.payload;
        })
            .addCase(tenants_1.updateTenantProfile.rejected, (state) => {
            state.createTenantStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error updating the office profile', {
                type: 'error',
            });
        });
        // Analytics
        builder
            .addCase(tenants_1.fetchAnalytics.pending, (state) => {
            state.analyticsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.fetchAnalytics.fulfilled, (state, action) => {
            if (action.payload) {
                state.appointmentsToday = action.payload.AppointmentsToday;
                state.scheduledProduction = action.payload.ScheduledProduction;
                state.unscheduledTx = action.payload.UnscheduledTx;
                state.unscheduledTxPatients = action.payload.UnscheduledTxPatients;
                state.pastDueAR = action.payload.PastDueAR;
                state.newPatientsScheduled = action.payload.NewPatientsScheduled;
                state.unscheduledFamily = action.payload.UnscheduledFamily;
                state.unscheduledHygiene = action.payload.UnscheduledHygiene;
                state.analyticsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            }
            else {
                state.analyticsStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the analytics data', {
                    type: 'error',
                });
            }
        })
            .addCase(tenants_1.fetchAnalytics.rejected, (state) => {
            state.analyticsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the analytics data', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.analyticsFilteredData.pending, (state) => {
            state.appointmentAnalytics = [];
            state.analyticsStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(tenants_1.analyticsFilteredData.fulfilled, (state, action) => {
            state.appointmentAnalytics = action.payload;
            state.analyticsStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
        })
            .addCase(tenants_1.analyticsFilteredData.rejected, (state) => {
            state.analyticsStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the analytics data', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.monthlyScheduledProduction.pending, (state) => {
            state.completedProduction = 0;
            state.scheduleProduction = 0;
        })
            .addCase(tenants_1.monthlyScheduledProduction.fulfilled, (state, action) => {
            if (action.payload) {
                state.completedProduction = action.payload.CompletedProduction;
                state.scheduleProduction = action.payload.ScheduledProduction;
            }
        })
            .addCase(tenants_1.monthlyScheduledProduction.rejected, () => {
            (0, react_toastify_1.toast)('Error loading the analytics data', {
                type: 'error',
            });
        });
        builder
            .addCase(tenants_1.getPatientProductionStat.pending, (state) => {
            state.grossProduction = 0;
            state.grossHygieneProduction = 0;
            state.grossDentistProduction = 0;
            state.netProduction = 0;
            state.netHygieneProduction = 0;
            state.netDentistProduction = 0;
            state.grossProductionNewPatient = 0;
            state.netProductionNewPatient = 0;
            state.totalPatients = 0;
            state.newPatients = 0;
        })
            .addCase(tenants_1.getPatientProductionStat.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            if (action.payload) {
                state.grossProduction = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.GrossProduction;
                state.grossHygieneProduction = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.GrossHygieneProduction;
                state.grossDentistProduction = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.GrossDentistProduction;
                state.netProduction = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.NetProduction;
                state.netHygieneProduction = (_e = action.payload) === null || _e === void 0 ? void 0 : _e.NetHygieneProduction;
                state.netDentistProduction = (_f = action.payload) === null || _f === void 0 ? void 0 : _f.NetDentistProduction;
                state.grossProductionNewPatient = (_g = action.payload) === null || _g === void 0 ? void 0 : _g.GrossProductionNewPatient;
                state.netProductionNewPatient = (_h = action.payload) === null || _h === void 0 ? void 0 : _h.NetProductionNewPatient;
                state.totalPatients = (_j = action.payload) === null || _j === void 0 ? void 0 : _j.TotalPatients;
                state.newPatients = (_k = action.payload) === null || _k === void 0 ? void 0 : _k.NewPatients;
            }
        })
            .addCase(tenants_1.getPatientProductionStat.rejected, () => {
            (0, react_toastify_1.toast)('Error loading the analytics data', {
                type: 'error',
            });
        });
    },
});
_a = exports.tenantsSlice.actions, exports.setSelectedTenant = _a.setSelectedTenant, exports.setActivationKey = _a.setActivationKey, exports.setTheme = _a.setTheme;
exports.default = exports.tenantsSlice.reducer;
