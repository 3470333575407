"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const x_date_pickers_1 = require("@mui/x-date-pickers");
const react_1 = require("react");
const DatePickers = ({ value, handleDateChange, label, setValue, setQueryObject }) => {
    (0, react_1.useEffect)(() => {
        return () => {
            setValue(null);
            setQueryObject((data) => (Object.assign(Object.assign({}, data), { from: null, to: null })));
        };
    }, [setQueryObject, setValue]);
    return (0, jsx_runtime_1.jsx)(x_date_pickers_1.DatePicker, { className: 'datepicker', value: value, onChange: handleDateChange, closeOnSelect: true, label: label, format: 'DD-MM-YY' });
};
exports.default = DatePickers;
