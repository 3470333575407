"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProcFee = exports.setUnpaidAppointments = exports.unpaidAppointmentSlice = void 0;
const react_toastify_1 = require("react-toastify");
const toolkit_1 = require("@reduxjs/toolkit");
const constants_1 = require("@appConstants/constants");
const unpaidAppointments_1 = require("@services/unpaidAppointments");
const uuid_1 = require("uuid");
const initialState = {
    treatments: {
        PatientId: 0,
        LastName: '',
        FirstName: '',
        TotalAmount: 0,
        Procedures: [],
        Definitions: [],
    },
    getAppointmentStatus: constants_1.REQUEST_STATUS.IDLE,
};
exports.unpaidAppointmentSlice = (0, toolkit_1.createSlice)({
    name: 'Unpaid Appointments',
    initialState,
    reducers: {
        setUnpaidAppointments: (state, action) => {
            state.treatments = action.payload;
        },
        updateProcFee: (state, action) => {
            const { procNum, adj } = action.payload;
            const procedureIndex = state.treatments.Procedures.findIndex((procedure) => procedure.ProcNum === procNum);
            if (procedureIndex !== -1) {
                const procedure = state.treatments.Procedures[procedureIndex];
                const procFee = procedure.AccProcFee ? procedure.AccProcFee - adj : -adj;
                state.treatments.Procedures[procedureIndex] = Object.assign(Object.assign({}, procedure), { Adjustment: adj, ProcFee: procFee });
            }
            else {
                console.log('Procedure not found');
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(unpaidAppointments_1.fetchUnpaidAppointments.pending, (state) => {
            state.getAppointmentStatus = constants_1.REQUEST_STATUS.PENDING;
        })
            .addCase(unpaidAppointments_1.fetchUnpaidAppointments.fulfilled, (state, action) => {
            if (!action.payload) {
                state.getAppointmentStatus = constants_1.REQUEST_STATUS.FAILED;
                (0, react_toastify_1.toast)('Error loading the unpaid appointments', {
                    type: 'error',
                });
                return;
            }
            state.getAppointmentStatus = constants_1.REQUEST_STATUS.SUCCEEDED;
            state.treatments = Object.assign(Object.assign({}, action.payload), { Procedures: action.payload.Procedures.map((procedure) => (Object.assign(Object.assign({}, procedure), { id: (0, uuid_1.v4)() }))) });
        })
            .addCase(unpaidAppointments_1.fetchUnpaidAppointments.rejected, (state) => {
            state.getAppointmentStatus = constants_1.REQUEST_STATUS.FAILED;
            (0, react_toastify_1.toast)('Error loading the unpaid appointments', {
                type: 'error',
            });
        });
    },
});
_a = exports.unpaidAppointmentSlice.actions, exports.setUnpaidAppointments = _a.setUnpaidAppointments, exports.updateProcFee = _a.updateProcFee;
exports.default = exports.unpaidAppointmentSlice.reducer;
